<template>
  <v-container>
    <!-- <v-container class="d-flex justify-center"> -->
    <h2 class="text-h4">The artwork of renowned artist, Tien-Wei Chang</h2>
    <!-- </v-container> -->

    <v-row class="mt-8">
      <v-col cols="12" sm="6">
        <v-img src="~images/chang-tien-wei-1.jpeg" contain></v-img>
      </v-col>
      <v-col cols="12" sm="6">
        <h2>Flexible column</h2>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
          qui ratione voluptatem sequi nesciunt.
        </p>
      </v-col>
    </v-row>

    <v-container class="text-block mt-15">
      <p>
        Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
        vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
        aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
        qui ratione voluptatem sequi nesciunt. Sed ut perspiciatis unde omnis
        iste natus error sit voluptatem accusantium doloremque laudantium, totam
        rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
        architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
        quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
        magni dolores eos qui ratione voluptatem sequi nesciunt.
      </p>
    </v-container>

    <v-container class="d-flex justify-center mt-15">
      <v-btn
        x-large
        color="#862042"
        class="white--text"
        :to="{ name: 'collection' }"
      >
        View the Collection
      </v-btn>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
.text-block {
  padding: 30px;
  font-size: 20px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}
</style>
